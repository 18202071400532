import { FC, useState, useRef, useEffect } from "react";
import cx from "classnames";
import { Document, Page, pdfjs } from "react-pdf";
import Dialog, {
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@/components/Dialog";
import Button from "@/components/Button";
import { DownloadIcon, RedirectIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import { useResourceSignedUrlQuery } from "@/fetch/gworld";
import { Skeleton } from "@/components/Loader";
import { useOpenExternalURL, useResponsive, useTrackers } from "@/hooks";
import styles from "./ResourcePdfViewer.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  resourceId: number;
  onClose: () => void;
  title: string;
  isDownloadable?: boolean;
  open: boolean;
};

const ResourcePdfViewer: FC<Props> = ({
  resourceId,
  onClose,
  title,
  isDownloadable,
  open,
}) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const openExternalLink = useOpenExternalURL();
  const { isMobile } = useResponsive();
  const { track } = useTrackers();
  const pagesContainerRef = useRef<HTMLDivElement | null>(null);
  const mainPagesContainerRef = useRef<HTMLDivElement | null>(null);
  const pagesRef = useRef<Array<any>>([]);
  const sidePagesRef = useRef<Array<any>>([]);

  const {
    data: resourceResult,
    isLoading: isGettingUrl,
    isFetched: isUrlFetched,
  } = useResourceSignedUrlQuery(
    resourceId,
    {},
    { refetchOnWindowFocus: false, cacheTime: 0 }
  );
  const { data: downloadResourceResult, isLoading: isGettingDownloadUrl } =
    useResourceSignedUrlQuery(
      resourceId,
      { download: true },
      { refetchOnWindowFocus: false, cacheTime: 0 }
    );

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
    setIsLoaded(!isLoaded);
  };

  const pdfUrl = resourceResult?.url;
  const downloadUrl = resourceResult?.url;

  const pagesArray = new Array(numPages).fill(1);

  const openInNewTab = () => {
    openExternalLink(pdfUrl || "");
  };

  const onDownload = () => {
    openExternalLink(downloadUrl || "");
    track("Resource PDF Downloaded", {
      eventId: "resource-pdf-downloaded",
      resourceId: resourceId,
    });
  };

  const isLoading = isGettingUrl && !isUrlFetched;

  const mobilePageWidth = pagesContainerRef.current?.offsetWidth || 300;
  const mobilePageHeight = mobilePageWidth * 1.41;

  useEffect(() => {
    track("Resource PDF Viewer Opened", {
      eventId: "resource-pdf-viewer-opened",
      resourceId: resourceId,
    });
  }, []);

  const handleChangePage = (newPage: number) => {
    track("Resource PDF Page Changed", {
      eventId: "resource-pdf-page-changed",
      resourceId: resourceId,
      page: newPage,
    });
    setPageNumber(newPage);
    const pageEl: HTMLElement = pagesRef.current?.[newPage - 1];
    mainPagesContainerRef.current?.scrollTo({
      top: (newPage - 1) * 1044,
      behavior: "smooth",
    });
    // pageEl?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const handleChangePage = () => {
      const topPosition = window.innerHeight / 2 - 100;
      const currentSelectedItemNumber = pagesRef.current.findIndex((each) => {
        return (each?.getBoundingClientRect().top || 0) > topPosition;
      });
      const sidePageEl: HTMLElement =
        sidePagesRef.current?.[currentSelectedItemNumber - 1];
      const sideTopPosition = window.innerHeight / 2 - 287;
      const sideBottomPosition = window.innerHeight / 2 + 300;
      if (
        !(
          (sidePageEl?.getBoundingClientRect()?.top || 0) > sideTopPosition &&
          (sidePageEl?.getBoundingClientRect()?.bottom || 0) <
            sideBottomPosition
        )
      ) {
        pagesContainerRef.current?.scroll({
          top: (currentSelectedItemNumber - 1) * 298,
          behavior: "smooth",
        });
      }
      setPageNumber(currentSelectedItemNumber);
    };

    mainPagesContainerRef.current?.addEventListener("scroll", handleChangePage);

    return () => {
      mainPagesContainerRef.current?.removeEventListener(
        "scroll",
        handleChangePage
      );
    };
  }, [
    mainPagesContainerRef.current,
    pagesRef.current,
    isLoaded,
    sidePagesRef.current,
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: styles.dialogPaper,
      }}
    >
      <DialogTitle
        hasCloseButton
        onClose={onClose}
        className={styles.dialogTitle}
      >
        {title}
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {isLoading ? (
          <div className={styles.documentContainer}>
            <div className={styles.leftContainer}>
              {[1, 2, 3].map((each) => (
                <Skeleton
                  key={each}
                  variant="rectangular"
                  width={178}
                  height={251}
                  className={styles.loadingSidePage}
                />
              ))}
            </div>
            <div className={styles.mainPageContainer}>
              <Skeleton
                variant="rectangular"
                className={styles.loadingMainPage}
              />
            </div>
          </div>
        ) : (
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            className={styles.documentContainer}
            loading={
              <div className={styles.documentContainer}>
                <div className={styles.leftContainer}>
                  {[1, 2, 3].map((each) => (
                    <Skeleton
                      key={each}
                      variant="rectangular"
                      width={178}
                      height={251}
                      className={styles.loadingSidePage}
                    />
                  ))}
                </div>
                <div
                  className={styles.mainPageContainer}
                  style={{ marginLeft: 48 }}
                >
                  <Skeleton
                    variant="rectangular"
                    className={styles.loadingMainPage}
                  />
                </div>
              </div>
            }
          >
            <div className={styles.leftContainer} ref={pagesContainerRef}>
              {pagesArray.map((each, idx) => (
                <div key={idx} className={styles.sidePageContainer}>
                  <Page
                    canvasRef={(el) => (sidePagesRef.current[idx] = el)}
                    pageNumber={idx + 1}
                    className={cx(styles.sidePage, {
                      [styles.pageIndicator]:
                        pageNumber === idx + 1 && !isMobile,
                    })}
                    width={isMobile ? mobilePageWidth : 178}
                    height={isMobile ? mobilePageHeight : 251}
                    onClick={
                      isMobile ? undefined : () => handleChangePage(idx + 1)
                    }
                  />

                  <Typography variant="body1" color="text.contrast">
                    {idx + 1}
                  </Typography>
                </div>
              ))}
            </div>
            <div
              className={styles.mainPagesContainer}
              ref={mainPagesContainerRef}
            >
              {pagesArray.map((each, idx) => (
                <div className={styles.mainPageContainer} key={idx}>
                  <Page
                    canvasRef={(el) => (pagesRef.current[idx] = el)}
                    pageNumber={idx + 1}
                    className={styles.mainPage}
                    width={727}
                    height={1028}
                  />
                </div>
              ))}
            </div>
          </Document>
        )}
      </DialogContent>
      {isDownloadable && (
        <DialogActions className={styles.actionContainer}>
          <Button
            startIcon={<DownloadIcon />}
            onClick={onDownload}
            className={styles.downloadButton}
          >
            Download
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ResourcePdfViewer;
